
export default {
    name: 'ErrorLayout',
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
    computed: {
        errorCode() {
            return String(this.error.statusCode);
        },
    },
};
